<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-09-26 17:02:09
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-01-17 13:57:29
 * @FilePath: \yda_web_manage\src\views\equipmentManage\index.vue
 * @Description: 终端管理
-->
<template>
  <div class="equipment-manage">
    <!-- 筛选模块 -->
    <div class="filter">
      <a-form layout="inline">
        <!-- 终端编号 -->
        <a-form-item label="终端编号：" class="block">
          <a-input
            class="form-input"
            allowClear
            :maxlength="20"
            v-model:value.trim="equipment.number"
            placeholder="请输入"
          >
          </a-input>
        </a-form-item>
        <!-- 所属组织 -->
        <a-form-item label="所属组织：" class="block">
          <a-select class="form-select" v-model:value="equipment.organization" placeholder="请选择">
            <!-- TODO:value 动态绑定 -->
            <a-select-option :value="item.code" v-for="(item, index) in organizationOptions" :key="index">{{
              item.desc
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <!-- 终端类型 -->
        <a-form-item label="终端类型：" class="block">
          <a-select class="form-select" v-model:value="equipment.type" placeholder="请选择">
            <!-- TODO:value 动态绑定 -->
            <a-select-option :value="item.desc" v-for="(item, index) in typeOptions" :key="index">{{
              item.desc
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <!-- 终端型号 -->
        <a-form-item label="终端型号：" class="block">
          <a-select class="form-select" v-model:value="equipment.model" placeholder="请选择">
            <!-- TODO:value 动态绑定 -->
            <a-select-option :value="item.desc" v-for="(item, index) in modelOptions" :key="index">{{
              item.desc
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item class="form-btns" style="margin-right: 0;">
          <a-button @click="reset" class="reset">重置</a-button>
          <a-button @click="search" type="primary">查询</a-button>
        </a-form-item>
      </a-form>
    </div>
    <!-- 终端列表 -->
    <div class="equipment-list">
      <div class="table-head">
        <span class="title">终端列表</span>
      </div>
      <a-table
        :columns="equipmentColumns"
        :dataSource="dataSource"
        :pagination="pagination"
        @change="tableChange"
        :loading="tableLoading"
        rowKey="index"
        ref="tableRef"
        :scroll="{ x: 400 }"
      >
        <!-- 所属组织 -->
        <template #organization="{ text }">
          <div class="text-box">
            <a-tooltip placement="bottomLeft" v-if="text?.length > 12">
              <template #title>
                <span>{{ text }}</span>
              </template>
              {{ text ?? '-' }}
            </a-tooltip>
            <span v-else>{{ text ?? '-' }}</span>
          </div>
        </template>
        <!-- 设置 -->
        <!-- info:金盾终端需显示设置按钮 -->
        <template #action="{ record }">
          <span @click="goSetting(record)" class="setting" v-btn="'PC_EQUIPMENT_MANAGE'">设置</span>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, ref, computed } from 'vue'
import { equipmentColumns } from './columns'
import { paginationParams } from '@/utils/constData'
import { useRouter } from 'vue-router'
import { getDeviceList } from '@/apis/deviceManage'
import { getEnums } from '@/apis/user'
import { useStore } from 'vuex'
import { sealCabinetApi } from '@/apis/sealCabinet'

export default defineComponent({
  components: {},
  props: {},
  setup() {
    const store = useStore()
    const organizationOptions = ref([])
    const typeOptions = ref([])
    const modelOptions = ref([])
    const router = useRouter()
    const equipment = reactive({
      number: undefined,
      organization: undefined,
      type: undefined,
      model: undefined
    })
    const state = reactive({
      dataSource: [],
      pagination: {
        ...paginationParams
      },
      currentNum: 1,
      tableLoading: true
    })
    //分页、排序、筛选变化时触发
    const tableChange = ({ current, pageSize }) => {
      state.dataSource = []
      state.pagination.current = current
      state.pagination.pageSize = pageSize
      state.currentNum = current
      getList()
    }
    //获取终端列表
    const getList = () => {
      state.tableLoading = true
      const params = {
        number: equipment.number, //终端编号
        equipmentType: equipment.type, //终端类型
        equipmentModel: equipment.model, //终端型号
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize
      }
      getDeviceList(params)
        .then(res => {
          if (res.success) {
            console.log('终端列表', res)
            state.dataSource = res.data
            state.pagination.total = res.totalItem
            state.pagination.index = res.pageIndex
            state.tableLoading = false
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
    //设置
    const goSetting = async data => {
      let isPush = true
      let _path = '/equipmentManage/detail'
      if (data.equipmentModel === 'MD600-A') {
        _path = '/equipmentManage/yzgDetail'
        //印章柜未初始化时，不跳进去
        await sealCabinetApi
          .checkCabinetInit({
            equipmentId: data.id
          })
          .then(res => {
            if (!res.data) {
              isPush = false
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
      if (isPush) {
        router.push({
          path: _path,
          query: {
            equipmentId: data.id
          }
        })
      }
    }
    //重置
    const reset = () => {
      equipment.number = undefined
      equipment.organization = undefined
      equipment.type = undefined
      equipment.model = undefined
      getList()
    }

    const getDeviceInfo = () => {
      //获取终端类型
      getEnums({ kind: 'EQUIPMENT_MODEL_TYPE' }).then(res => {
        if (res.success) {
          typeOptions.value = res.data
          console.log('终端类型的值', res.data)
        }
      })
      //获取终端型号
      getEnums({ kind: 'EQUIPMENT_MODEL' }).then(res => {
        if (res.success) {
          modelOptions.value = res.data
          console.log('终端型号的值', res.data)
        }
      })
      //获取所属组织
      const enterpriseName = computed(() => store.state.user.userInfo.result?.enterpriseName)
      const enterpriseId = computed(() => store.state.user.userInfo.enterpriseId)
      organizationOptions.value = [
        {
          desc: enterpriseName,
          code: enterpriseId
        }
      ]
    }
    //查询
    const search = () => {
      state.pagination.current = 1
      getList()
    }
    onMounted(() => {
      getDeviceInfo()
      getList()
    })
    return {
      equipment,
      reset,
      search,
      equipmentColumns,
      tableChange,
      ...toRefs(state),
      getList,
      goSetting,
      organizationOptions,
      typeOptions,
      modelOptions,
      getDeviceInfo
    }
  }
})
</script>
<style lang="less" scoped>
.equipment-manage {
  padding: 0 25px;

  .filter {
    padding: 24px;
    background: #ffffff;
    border-radius: 2px;

    .block {
      margin-right: 16px;

      .form-input,
      .form-select {
        width: 145px;
      }
    }

    .reset {
      margin-right: 8px;
    }
  }

  .equipment-list {
    padding: 20px 24px 24px;
    background: #ffffff;
    margin-top: 24px;
    border-radius: 2px;

    .table-head {
      margin-bottom: 20px;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 24px;
      }
    }

    .setting {
      font-size: 14px;
      font-weight: 400;
      color: #1890ff;
      line-height: 22px;
      cursor: pointer;
    }

    .text-box {
      width: 12em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
